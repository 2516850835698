import React from 'react';
import LocaleRedirect from '@rentivo/gatsby-core/src/components/navigation/LocaleRedirect';

const RouteLinkComponent = ({route, routePaths, queryString}) => {
  return (
    <LocaleRedirect to={`/${routePaths[route]}/${queryString}`}/>
  );
};

export default RouteLinkComponent;
