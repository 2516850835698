import React from 'react';
import PropertyLayout from '@rentivo/gatsby-theme-barcelona/src/templates/Property/components/PropertyLayout';
import PropertyProvider from '@rentivo/gatsby-core/src/containers/PropertyProvider';
import PricingProvider from '@rentivo/gatsby-core/src/containers/PricingProvider';

const PropertyComponent = (props) => {
  return (
    <PropertyProvider>
      <PricingProvider>
        <PropertyLayout {...props}/>
      </PricingProvider>
    </PropertyProvider>
  );
};

export default PropertyComponent;
