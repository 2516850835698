import React from 'react'
import { PropertyProvider } from '@rentivo/gatsby-core/src/context/PropertyContext'
import { Redirect, Router, useParams } from '@reach/router'
import { useElasticSearchDocument } from '@rentivo/gatsby-core/src/hooks/useElasticSearch/index'
import Loader from '@rentivo/gatsby-core/src/components/generic/Loader'
import {
  hasData,
  isDataEmpty,
} from '@rentivo/gatsby-core/src/containers/ESProvider/utils'
import RouteRedirect from '@rentivo/gatsby-core/src/components/navigation/RouteRedirect'
import { generateLocaleLink } from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/utils'

const PropertyProviderDynamic = ({
  childItems,
  search,
  pathname,
  routePaths,
}) => {
  const params = useParams()
  const propertyIdManual = pathname ? pathname.match(/([^\/]*)\/*$/)?.[1] : null

  const { propertyId } = useParams()

  const useId = propertyIdManual ? propertyIdManual : propertyId

  if (!useId) return <RouteRedirect route={`ROUTE_BASE_SEARCH`} />

  const { data, loading, error, didInvalidate } = useElasticSearchDocument({
    key: useId,
    query: {
      size: 1,
      query: {
        bool: {
          should: [
            { term: { '_id': useId } },
            { term: { '$marketing.slug.keyword': useId } },
            { term: { '$marketing.rentivoSlug.keyword': useId } },
          ],
        },
      },
    },
  });

  if (search && search.includes('debug')) {
    console.log(data);
  }

  return (
    <>
      {loading && <Loader styleProps={{ position: 'fixed' }} />}
      {hasData(data) && (
        <PropertyProvider property={data}>{childItems}</PropertyProvider>
      )}
      {isDataEmpty(data) && <RouteRedirect route={`ROUTE_BASE_NOT_FOUND`} />}
      {didInvalidate && error && <h2>{error.message}</h2>}
    </>
  )
}

const PropertyProviderComponent = ({
  dynamicPropertyPages,
  routePaths,
  defaultLang,
  lang,
  children,
  location,
  property,
}) => {
  const { search, pathname } = location

  if (search && search.includes('debug')) {
    console.log(location, property)
  }

  if ((property || !dynamicPropertyPages)) {
    console.log('Property Load Type: STATIC');

    return <PropertyProvider property={property}>{children}</PropertyProvider>
  } else {
    let routeBaseProperty = routePaths.ROUTE_BASE_PROPERTY;

    if (pathname.includes(`/${routePaths.ROUTE_BASE_PROPERTY_PUBLIC}`)) {
      routeBaseProperty = routePaths.ROUTE_BASE_PROPERTY_PUBLIC;
    }

    const path = generateLocaleLink(
      lang,
      defaultLang,
      `/${routeBaseProperty}/:propertyId`
    )

    console.log('Property Load Type: DYNAMIC');

    return (
      <Router>
        <PropertyProviderDynamic
          routePaths={routePaths}
          childItems={children}
          path={path}
          pathname={pathname}
          search={search}
        />
      </Router>
    )
  }
}

export default PropertyProviderComponent
