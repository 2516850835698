import React from 'react';
import { compose } from 'redux';
import { withPageContext } from '@rentivo/gatsby-core/src/context/PageContext';
import PropertyProvider from '@rentivo/gatsby-core/src/containers/PropertyProvider/component';
import { createSelector } from 'reselect';
import { selectPageContextProperty } from '@rentivo/gatsby-core/src/selectors/pageContext';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';
import {
  selectDynamicPropertyPagesConfig,
  selectRoutePathsConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { connect } from 'react-redux';
import { selectDefaultLang, selectLang } from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';

PropertyProvider.defaultProps = {
  property: null
};

const mapStateToProps = createSelector(
  selectRoutePathsConfig,
  selectDynamicPropertyPagesConfig,
  selectDefaultLang,
  selectLang,
  (routePaths, dynamicPropertyPages, defaultLang, lang) => ({
    dynamicPropertyPages,
    routePaths,
    defaultLang,
    lang
  })
);

const pageContextSelector = (state, { property, location: { state: routerState } }) => createSelector(
  selectPageContextProperty,
  (contextProperty) => {
    property = (routerState && routerState.property) ? routerState.property : property; // Attempt to get property from router state first
    return {
      property: (property) ? property : contextProperty
    };
  }
)(state);

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
  withPageContext(pageContextSelector)
);

export default enhance(PropertyProvider);
