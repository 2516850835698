import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createSelector } from 'reselect';
import LocaleRedirect from '@rentivo/gatsby-core/src/components/navigation/LocaleRedirect/component';
import { withRouter } from '@rentivo/gatsby-core/src/context/RouterContext';
import {
  selectDefaultLang,
  selectLang,
} from '@rentivo/gatsby-core/src/containers/LanguageProvider/selectors';
import {
  selectRoutePathsConfig,
  selectSiteBaseUrlConfig
} from '@rentivo/gatsby-core/src/selectors/siteConfig';
import { generateLocaleLink } from '@rentivo/gatsby-core/src/components/navigation/LocaleLink/utils';

LocaleRedirect.defaultProps = {
  to: '/'
};

const mapStateToProps = (state, { to }) => createSelector(
  selectRoutePathsConfig,
  selectLang,
  selectDefaultLang,
  selectSiteBaseUrlConfig,
  (routePaths, lang, defaultLang, baseUrl) => ({
    routePaths,
    lang,
    defaultLang,
    baseUrl,
    localeTo: generateLocaleLink(lang, defaultLang, to)
  })
);

const enhance = compose(
  withRouter,
  connect(mapStateToProps),
);

export default enhance(LocaleRedirect);
