export const routePathKeys = {
  ROUTE_BASE_SEARCH: 'ROUTE_BASE_SEARCH',
  ROUTE_BASE_PROPERTY: 'ROUTE_BASE_PROPERTY',
  ROUTE_BASE_PROPERTY_PUBLIC: 'ROUTE_BASE_PROPERTY_PUBLIC',
  ROUTE_BASE_CHECKOUT: 'ROUTE_BASE_CHECKOUT',
  ROUTE_BASE_CHECKOUT_REVIEW: 'ROUTE_BASE_CHECKOUT_REVIEW',
  ROUTE_BASE_CHECKOUT_GUEST_INFO: 'ROUTE_BASE_CHECKOUT_GUEST_INFO',
  ROUTE_BASE_CHECKOUT_CONFIRM: 'ROUTE_BASE_CHECKOUT_CONFIRM',
  ROUTE_BASE_CHECKOUT_SUCCESS: 'ROUTE_BASE_CHECKOUT_SUCCESS',
  ROUTE_BASE_POSTS: 'ROUTE_BASE_POSTS',
  ROUTE_BASE_EXPERIENCES: 'ROUTE_BASE_EXPERIENCES',
  ROUTE_BASE_POSTS_CATEGORIES: 'ROUTE_BASE_POSTS_CATEGORIES',
  ROUTE_BASE_POSTS_TAGS: 'ROUTE_BASE_POSTS_TAGS',
  ROUTE_BASE_POSTS_AUTHOR: 'ROUTE_BASE_POSTS_AUTHOR',
  ROUTE_PATH_HOME: 'ROUTE_PATH_HOME',
  ROUTE_BASE_NOT_FOUND: 'ROUTE_BASE_NOT_FOUND',
  ROUTE_BASE_CUSTOMER_CONTACT: 'ROUTE_BASE_CUSTOMER_CONTACT',
  ROUTE_BASE_BUSINESS_CONTACT: 'ROUTE_BASE_BUSINESS_CONTACT',
  ROUTE_BASE_DEDICATED_POST: 'ROUTE_BASE_DEDICATED_POST',
  ROUTE_BASE_HUB: "ROUTE_BASE_HUB"
};
